<template>
  <div>
    <p class="endorsement-title">{{ $t("report.endorsement_report_info_month") }}</p>
    <div class="graphic-container">
      <apexchart
        type="bar"
        height="400"
        :options="chartOptions"
        :series="series"
        width="100%"
      ></apexchart>
    </div>
      
  </div>
</template>
<script>
import { GET_ITEMS, LOADING } from "@/core/services/store/REST.module";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import moment from "moment";
export default {
  name: "EndorsementReportChart",
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    lineLength() {},
    chartOptions() {
      let length = 500;
      if (this.tmpSeries.length <= 15) {
        length = 700;
      } else {
        length = length + (this.tmpSeries.length - 7) * 30;
      }
      let tmpChartOptions = {
        fixedHeader: true,
        plotOptions: {
          bar: {
            horizontal: false, // Sütun grafiğinin yatay veya dikey olmasını belirtir
            barWidth: "25%", // Sütunların genişliği yüzde olarak belirtilir (örneğin, %80 genişlik)
            barPadding: 2, // Sütunlar arasındaki boşluk (örneğin, 4 piksel)
          },
        },

        defaultLocale: "tr",
        chart: {
          type: "bar",
          height: 350,
          width: length,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "rounded",
            columnWidth: "50%",
            barHeight: "20%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.chartCategories,
          tickPlacement: "on",
          hideOverlappingLabels: true,
          trim: false,
          minHeight: 200,
          rotate: -90,
          style: {
            fontSize: "12px",
            display: "inline-block",
            overflow: "hidden",
            fontWeight: 500,
          },
          title: {
            text: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          title: {
            text: "(Ciro)",
          },
          labels: {
            formatter: (val) => {
              return val.toLocaleString("tr-TR") + " " + "₺";
            },
          },
          formatter: (val) => {
            return val.toLocaleString("tr-TR") + " " + "₺";
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString("tr-TR") + " " + "₺";
            },
          },
        },
      };
      return tmpChartOptions;
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartCategories: [],

      tmpSeries: [],
      series: [],
    };
  },

  props: [
    "startDate",
    "endDate",
    "currencyRateOptions",
    "companyId",
    "orderId",
    "onClickButton",
    "monthly",
    "yearly",
  ],
  mounted() {
    this.getItems();
  },

  methods: {
    getItems() {
      this.series = [];
      this.chartCategories = [];
      let filters = {
        start_date: this.startDate,
        end_date: this.endDate,
        company_id: this.companyId,
        order_id: this.orderId,
      };
      if (this.monthly) {
        this.$set(filters, "monthly", JSON.stringify(this.monthly));
      }
      if (this.yearly) {
        this.$set(filters, "yearly", JSON.stringify(this.yearly));
      }
      this.$set(
        filters,
        "start_date",
        moment(this.startDate).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "end_date", moment(this.endDate).format(LARAVEL_DATE_FORMAT));

      this.$store
        .dispatch(GET_ITEMS, {
          filters: filters,
          url: "api/report/loading-deadline-chart-data",
        })
        .then((result) => {
          let packingWithOrderAmountSeries = [];
          let packingAmountSeries = [];
          let tmpData = result.data;
          let self = this;
          _.forEach(tmpData, function (chartData, key) {
            self.chartCategories.push(String(moment(key, "YYYY-MM").format("MM.YYYY")));
            let sumPackingListWithSalePrice = 0;
            let tmpSumPackingList = 0;

            chartData.forEach((item) => {
              let findCurrency = _.find(self.currencyRateOptions, {
                code: item.orderable.currency_unit.code,
              });

              sumPackingListWithSalePrice +=
                Number(item.orderable.sale_price) *
                Number(item.amount) *
                Number(findCurrency.rate);
              // Eğer Faturalandırılmış yani muhesebeye gönderilmişse bu toplama işlemi yapılıyor
              tmpSumPackingList += item.amount;
            });
            packingWithOrderAmountSeries.push(sumPackingListWithSalePrice.toFixed(4));
            self.tmpSeries.push(sumPackingListWithSalePrice.toFixed(4));
            packingAmountSeries.push(tmpSumPackingList.toFixed(4));
          });
          let tmpPackingWithOrderSeries = {
            name: this.$t("report.endorsement"),
            data: packingWithOrderAmountSeries,
          };
          let tmpPackingSeries = {
            name: this.$t("report.packing_list"),
            data: packingAmountSeries,
          };
          this.series.push(tmpPackingWithOrderSeries);
          this.series.push(tmpPackingSeries);
        });
    },
  },
  watch: {
    onClickButton: function (newVal, oldVal) {
      if (newVal == true) {
        this.getItems();
      }
    },
  },
};
</script>
<style scoped>
.endorsement-title {
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  margin-top: 5px;
  color: #5cb7e0;
}
.graphic-container {
  overflow-x: scroll;
  display: flex;
  position: relative;
}
</style>
