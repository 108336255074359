<template>
  <div class="row">
    <modal
      style="border-radius: 15px"
      :adaptive="true"
      height="auto"
      name="detailReport"
      width="1000"
    >
      <div class="card card-custom">
        <div class="card-body card-body-scroll mt-5">
          <div class="row card-inner-area">
            <div class="col-12 text-center border-bottom card-header">
              <h3 class="order-number-text">{{ tmpOrderData.order_number }}</h3>

              <i
                class="far fa-times-circle fa-3x close-button"
                @click="closeModalAndCancel('detailReport')"
              >
              </i>
            </div>
            <!-- Export dates -->
            <div class="col-6">
              <div class="row m-0 p-0">
                <div class="col-12 info-area">
                  <h5 class="main-title">{{ $t("report.planned") }}</h5>
                  <table class="table table-sm planned-table">
                    <thead>
                      <th>{{ $t("report.color") }}</th>
                      <th>{{ $t("report.date_and_amount") }}</th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(
                          orderSizeAndColor, key
                        ) in tmpOrderData.order_size_and_colors"
                        :key="key"
                      >
                        <td>
                          {{
                            orderSizeAndColor.color_name +
                            "(" +
                            orderSizeAndColor.color_number +
                            ")"
                          }}
                        </td>
                        <td>
                          <template v-if="orderSizeAndColor.export_dates.length > 0">
                            <div
                              v-for="exportDate in orderSizeAndColor.export_dates"
                              :key="exportDate.id"
                            >
                              <span
                                class="fa fa-eye eye-icon"
                                style="cursor: point"
                                :class="{
                                  'active-eye-button':
                                    exportDate == orderSizeAndColorExportDateBodySizes,
                                }"
                                @click="viewExportDateBodySize(exportDate)"
                              >
                              </span>
                              {{ moment(exportDate.date).format("DD.MM.YYYY") }}
                              <span class="font-weight-bold">
                                / ({{ sumAmountOrderSizeAndColorExportDate(exportDate) }})
                              </span>
                            </div>
                          </template>
                          <template v-else>
                            <div>
                              {{ $t("report.not_found_date") }}
                            </div>
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  class="col-12 info-area"
                  v-if="orderSizeAndColorExportDateBodySizes.body_sizes"
                >
                  <h5 class="main-title">
                    {{ $t("report.date") }}:
                    <span>
                      {{
                        moment(orderSizeAndColorExportDateBodySizes.date).format(
                          "DD.MM.YYYY"
                        )
                      }}
                      /
                      ({{
                        orderSizeAndColorExportDateBodySizes.total_amount
                      }})
                    </span>
                  </h5>

                  <table class="table table-sm planned-table">
                    <thead>
                      <th>{{ $t("report.size") }}</th>
                      <th>{{ $t("report.amount") }}</th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(
                          bodySize, key
                        ) in orderSizeAndColorExportDateBodySizes.body_sizes"
                        :key="key"
                      >
                        <td>{{ bodySize.name }}</td>
                        <td>{{ bodySize.amount }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- Packing List -->
            <div class="col-6">
              <div class="row m-0 p-0">
                <div class="col-12 info-area">
                  <h5 class="main-title">{{ $t("report.packing_list") }}</h5>
                  <table class="table table-sm planned-table">
                    <thead>
                      <th>{{ $t("report.entry_amount") }}</th>
                      <th>{{ $t("report.detail") }}</th>
                    </thead>
                    <tbody>
                      <tr v-for="(pack, key) in tmpOrderData.packing" :key="key">
                        <td>
                          {{ pack.amount }}
                        </td>
                        <td
                          class="flex align-content-center text-center align-items-center"
                        >
                          <button
                            type="button"
                            class="show-button btn btn-sm"
                            :class="{
                              'active-show-button': tmpPackingListData == pack.data,
                            }"
                            @click="onViewPackingListData(pack.data)"
                          >
                            {{ $t("report.show") }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-12 info-area" v-if="tmpPackingListData.length">
                  <h5 class="main-title">{{ $t("report.happening") }}</h5>
                  <table class="table table-sm planned-table">
                    <thead>
                      <th>{{ $t("report.size") }}</th>
                      <th>{{ $t("report.color") }}</th>
                      <th>{{ $t("report.amount") }}</th>
                      <th>{{ $t("report.laoding_date") }}</th>
                    </thead>
                    <tbody>
                      <tr v-for="(data, key) in tmpPackingListData" :key="key">
                        <td class="font-weight-bold">
                          {{ data.body_size }}
                        </td>
                        <td>{{ data.color_name }} ({{ data.color_number }})</td>
                        <td>
                          {{
                            Number(data.in_package) * Number(data.box_quantity) +
                            Number(data.broken_parcel)
                          }}
                        </td>
                        <td>
                          {{ moment(data.upload_date).format("DD.MM.YYYY") }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <div class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">
              <div class="col-lg-3 col-md-6 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date"
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date"
                  >
                  </date-filter>
                </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :item-per-row="1"
                  :model.sync="filters.monthly"
                  :not-list="false"
                  :options="convertArrayToObjectByKey(months, 'id', 'name')"
                  :title="$t('report.monthly') + ':'"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  :required="false"
                  input-max-width="125px"
                  input-width="150px"
                  name="name"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :item-per-row="1"
                  :model.sync="filters.yearly"
                  :not-list="false"
                  :options="yearOptions"
                  :title="$t('report.yearly') + ':'"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  :required="false"
                  input-max-width="125px"
                  input-width="150px"
                  name="name"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :item-per-row="1"
                  :max="1"
                  :model.sync="filters.company_id"
                  :not-list="true"
                  :options="customerOptions"
                  :title="$t('order.customer') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="150px"
                  name="order_company_id"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :item-per-row="1"
                  :max="1"
                  :model.sync="filters.order_id"
                  :not-list="true"
                  :options="orderOptions"
                  :title="$t('report.order_no') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="150px"
                  name="order_id"
                ></custom-multi-select>
              </div>
              <div
                class="col-xl-1 col-lg-1 col-md-1 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult()"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <!-- <dashboard-box :title="$t('report.endorsemet_report')">
        <template v-slot:preview>
          <LoadingDeadlineReportChart
            :start-date.sync="filters.start_date"
            :end-date.sync="filters.end_date"
            :onClickButton="onClickButton"
          ></LoadingDeadlineReportChart>
        </template>
      </dashboard-box> -->
      <dashboard-box :title="$t('esc.esc_list')">
        <template v-slot:preview>
          <div @contextmenu.prevent="onContextMenu">
            <data-table
              :striped="true"
              :bordered="false"
              :borderless="true"
              :fields.sync="fields"
              :hover="false"
              :items="formattedReport"
              :no-border-collapse="true"
              :outlined="false"
              :page.sync="page"
              :perPage.sync="perPage"
              :infiniteId="infiniteId"
              tableVariant="''"
              @hitBottom="onHitBottom"
              :sortBy.sync="order"
              :sortDesc.sync="sort"
              @onClickView="onClickView"
            ></data-table>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import LoadingDeadlineReportChart from "./LoadingDeadlineReportChart";

import {
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  ITEMS,
  SET_FILTER,
  FILTER,
} from "@/core/services/store/report/loadingDeadlineReport.module";

import { GET_ITEMS } from "@/core/services/store/REST.module";
import {
  LARAVEL_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_TIME_FORMAT,
} from "@/core/config/constant";
import * as _ from "lodash";
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";
import {
  LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME,
  LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names";

export default {
  name: "loadingDeadlineReport",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    CustomMultiSelect,
    ContextMenu,
    LoadingDeadlineReportChart,
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER,
      items: ITEMS,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    yearOptions() {
      const currentYear = new Date().getFullYear();
      const years = [];
      const yearsToGenerate = 60;

      for (let i = 0; i < yearsToGenerate; i++) {
        const year = currentYear - i;
        years.push({ id: year, name: year });
      }
      return this.convertArrayToObjectByKey(years, "id", "name");
    },
    formattedReport() {
      if (!this.items || this.items.data === null || this.items.data === undefined)
        return [];
      let temp = [];
      this.items.data.forEach((item) => {
        let findCurrency = _.find(this.currencyRateOptions, {
          code: item.currency_unit.code,
        });
        let sumPackingListAmount = _.sumBy(item.packing, function (o) {
          return o.amount;
        });
        let endorsement = Number(sumPackingListAmount) * Number(item.sale_price);
        let endorsementTL = endorsement * Number(findCurrency.rate);
        let packing = _.orderBy(_.cloneDeep(item.packing), ["id"], ["desc"]);
        let lastUser = packing[0] ? packing[0].user.name : "";
        let lastAmount = packing[0] ? packing[0].amount : "";
        temp.push({
          id: item.id,
          customer: item.company.name,
          customer_representative: item.user.name,
          order_number: item.order_number,
          loaded_quantity: Number(sumPackingListAmount).toLocaleString("tr-TR"),
          loaded_date: moment(item.order_date).format("DD.MM.YYYY"),
          last_created_data_by_user: lastUser,
          last_created_data_by_amount: Number(lastAmount).toLocaleString("tr-TR"),
        });
      });
      return temp;
    },
  },
  data() {
    return {
      // Filters & pagination
      infiniteId: 1,
      sort: "desc",
      order: "id",
      sampleStatuses: [],
      fieldsToViewEdit: [],
      customerOptions: [],
      search: null,
      paginate: [],
      orderSizeAndColorExportDateBodySizes: [],
      page: 1,
      perPage: 25,
      currentStoreType: 99999999,
      startDate: moment().subtract(30, "d"),
      endDate: moment().add(1, "d"),
      download: false,
      reportListUrl: "api/report/loading-deadline",
      currencyRateOptions: [],
      companies: [],
      orderOptions: [],
      tmpOrderData: [],
      tmpPackingListData: [],
      moment: moment,
      onClickButton: false,
      months: [
        {
          id: "01",
          name: this.$t("dateTime.january_full"),
        },
        {
          id: "02",
          name: this.$t("dateTime.february_full"),
        },
        {
          id: "03",
          name: this.$t("dateTime.march_full"),
        },
        {
          id: "04",
          name: this.$t("dateTime.april_full"),
        },
        {
          id: "05",
          name: this.$t("dateTime.may_full"),
        },
        {
          id: "06",
          name: this.$t("dateTime.june_full"),
        },
        {
          id: "07",
          name: this.$t("dateTime.july_full"),
        },
        {
          id: "08",
          name: this.$t("dateTime.august_full"),
        },
        {
          id: "09",
          name: this.$t("dateTime.september_full"),
        },
        {
          id: "10",
          name: this.$t("dateTime.october_full"),
        },
        {
          id: "11",
          name: this.$t("dateTime.november_full"),
        },
        {
          id: "12",
          name: this.$t("dateTime.december_full"),
        },
      ],
      fields: [
        {
          key: "customer",
          label: this.$t("report.customer"),
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "order_number",
          label: this.$t("report.order_number"),
          sortable: false,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "customer_representative",
          label: this.$t("order.customer_representative"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "loaded_quantity",
          label: this.$t("report.loaded_quantity"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "loaded_date",
          label: this.$t("report.loaded_date"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "last_created_data_by_user",
          label: this.$t("report.last_created_data_by_user"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "last_created_data_by_amount",
          label: this.$t("report.last_created_data_by_amount"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "view",
          label: "#",
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
      ],
    };
  },
  methods: {
    ...mapMutations({
      setFilter: SET_FILTER,
    }),
    viewExportDateBodySize(exportDate) {
      this.orderSizeAndColorExportDateBodySizes = [];
      let tmpItem = exportDate;
      if (typeof exportDate.body_sizes == "string") {
        exportDate.body_sizes = JSON.parse(exportDate.body_sizes);
        tmpItem = exportDate;
      }
      tmpItem.total_amount = _.sumBy(tmpItem.body_sizes, function(item) { return Number(item.amount); })
      this.orderSizeAndColorExportDateBodySizes = tmpItem;
    },
    sumAmountOrderSizeAndColorExportDate(exportDate) {
      let tmpExportDate = _.cloneDeep(exportDate);
      let sumAmount = 0;
      let bodySizes = tmpExportDate.body_sizes;
      if (typeof tmpExportDate.body_sizes == "string") {
        bodySizes = JSON.parse(tmpExportDate.body_sizes);
      }
      Object.entries(bodySizes).forEach((bodySize, key) => {
        sumAmount += Number(bodySize[1].amount);
      });
      return sumAmount;
    },
    closeModalAndCancel(name) {
      this.closeModalByName(name);
      this.orderSizeAndColorExportDateBodySizes = [];
    },
    onViewPackingListData(data) {
      this.tmpPackingListData = data;
    },
    onClickView(payload) {
      this.tmpOrderData = [];
      this.tmpPackingListData = [];
      this.tmpOrderData = _.find(this.items.data, { id: payload });
      this.orderSizeAndColorExportDateBodySizes = [];
      this.openModalByName("detailReport");
    },
    filterResult() {
      localStorage.setItem(
        LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME,
        moment(this.filters.start_date)
      );
      localStorage.setItem(
        LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME,
        moment(this.filters.end_date)
      );
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onClickButton = true;
      this.onHitBottom();
    },
    onHitBottom($state = null) {
      if (this.loading) {
        return;
      }
      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);

      self.$store
        .dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.reportListUrl,
          filters: filters,
          $state: $state,
        })
        .then((result) => {
          this.onClickButton = false;
          if (result.status) {
            if ($state) {
              this.infiniteId++;
              $state.loaded();
            }
          } else {
            if ($state) {
              this.infiniteId++;
              $state.complete();
            }
          }
        });
    },
    resetFilters() {
      (this.sort = "desc"),
        (this.order = "id"),
        this.setFilter({
          page: 1,
          per_page: 25,
          search: "",
          start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
          end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        });
    },
    setup() {
      let self = this,
        promises = [];
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/companies",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/currency-rate",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/report/order-list",
        })
      );

      Promise.all(promises).then((results) => {
        if (results[0].status) {
          self.customerOptions = self.convertArrayToObjectByKey(
            results[0].data,
            "id",
            "name"
          );
        }
        if (results[1].status) {
          self.companies = this.currencyRateOptions = results[1].data.data;
        }

        if (results[2].status) {
          self.orderOptions = results[2].data;
        }
      });
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("menu.loading_deadline_report") },
    ]);

    if (!this.isUserGranted("EscTable", ["viewAny"])) {
      return false;
    }

    if (!this.filter) {
      this.resetFilters();
    }

    this.filters.end_date = localStorage.hasOwnProperty(
      LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME))
      : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(
      LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME))
      : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);

    this.setup();

    setTimeout(this.onHitBottom, 500);
  },
  watch: {
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
  },
};
</script>

<style scoped>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px 10px 20px;
}
.card.card-custom > .card-body {
  padding: 0.3rem 0.3rem !important;
  margin: 0px !important;
}
.order-number-text {
  padding: 11px;
  margin-left: -26px;
  margin-top: -17px;
  padding-left: 25px;
  padding-right: 25px;
  color: white !important;
  border-radius: 5px;
  background: linear-gradient(
    180deg,
    rgb(102, 199, 232) 0%,
    rgb(64, 138, 198) 35%,
    rgb(70, 41, 133) 100%
  ) !important;
}

.vm--container > .vm--overlay > .vm--modal {
  border-radius: 15px !important;
}
.close-button {
  color: #45318a !important;
}
.close-button:hover {
  cursor: pointer;
  color: #694ec7 !important;
}
.info-area {
  padding: 15px 5px 5px 5px;
  margin: 15px 5px 5px 0px;
  border: 2px solid #e6e6e6;
  border-radius: 12px;
}
.planned-text {
  position: absolute;
  text-decoration: underline;
  text-underline-offset: 4px;
  top: 4px;
}
.planned-table,
th,
td {
  border: 2px solid #e6e6e6;
}
.card-inner-area {
  margin: 0px !important;
  padding: 0px !important;
}
.show-button {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  height: 30px;
}
.show-button:hover {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  height: 30px;
}
.active-show-button {
  color: #fff;
  background: linear-gradient(
    180deg,
    rgb(102, 199, 232) 0%,
    rgb(64, 138, 198) 35%,
    rgb(70, 41, 133) 100%
  ) !important;
}
.active-eye-button {
  color: rgb(36, 22, 67) !important;
}
.eye-icon {
  color: rgb(158, 158, 158);
}
.main-title {
  text-decoration: underline;
  text-underline-offset: 4px;
}
.main-title > span {
  font-weight: 400;
  font-size: 12;
}
</style>
